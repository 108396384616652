import React from "react";
const memo293 = "/Docs/Recruitment/NOTICE-_GROUP-A-GROUP-C.pdf";
const notmemo = "/Docs/Recruitment/NOTICE-FOR-INVITING-APPLICATIONS-OF-POSTS-GR-A-GR-C.pdf";
const notice766 = "/Docs/Recruitment/notice766.pdf";
const notice767 = "/Docs/Recruitment/notice767.pdf";
const form1 = "/Docs/Recruitment/form1.pdf";


export const RecruitmentComponent = () => {
  const handleDownload = (path) => {
    if (path) {
      window.open(path, "_blank", "noopener,noreferrer");
    } else {
      console.error("Download file not found.");
    }
  };

  return (
    <div class="container mt-5">
      <h2 class="text-center mb-4">Walk-in-Interview for the following posts : -</h2>
      <div className="table-responsive">
        <table className="table table-bordered">
          <thead className="thead-light">
            <tr>
              <th>SI No.</th>
              <th>Subject</th>
              <th>Memo No. & Date</th>
              <th>Click to Download</th>
              <th>Click to Download</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1.</td>
              <td>
                  Officer on Special Duty (OSD at the office of Board of
                  Auqaf,W.B.)
              </td>
              <td><b>Memo No :</b> 766<br/><b>Date :</b> 06.12.2024</td>
              <td><a target="_blank" class="btn btn-primary btn-sm" onClick={()=>handleDownload(notice766)}>Notice</a></td>
              <td>
                <a
                  target="_blank"
                  onClick={() => handleDownload(form1)}
                  className="btn btn-primary btn-sm"
                >
                  Application Form
                </a>
              </td>
            </tr>
            <tr>
              <td>2.</td>
              <td>
                  Superintendent at Hostel under Board of Auqaf, W.B.
              </td>
              <td><b>Memo No :</b> 766<br/><b>Date :</b> 06.12.2024</td>
              <td><a target="_blank" class="btn btn-primary btn-sm" onClick={()=> handleDownload(notice766)} >Notice</a></td>
              <td>
                <a
                  target="_blank"
                  onClick={() => handleDownload(form1)}
                  className="btn btn-primary btn-sm"
                >
                  Application Form
                </a>
              </td>
            </tr>
            <tr>
              <td>3.</td>
              <td>
                  Executive Officer, Hazrat Data Mehboob shah Wali (R.A) Waqf
                  Estate. Patahar Chapuri, Birbhum.
              </td>
              <td><b>Memo No :</b> 767<br/><b>Date :</b> 06.12.2024</td>
              <td><a target="_blank" class="btn btn-primary btn-sm" onClick={()=>handleDownload(notice767)}>Notice</a></td>
              <td>
                <a
                  target="_blank"
                  onClick={() => handleDownload(form1)}
                  className="btn btn-primary btn-sm"
                >
                  Application Form
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <h2 class="text-center mb-4">Recruitment Information</h2>
      <div class="card mb-3">
        <div class="card-header bg-primary text-white">
          Walk-in-Interview for Gr. A & Gr. C (Memo No. 293 dtd. 02.02.2021)
        </div>
        <div class="card-body">
          <p>
            Walk-in-Interview details for Group A and Group C positions as per
            Memo No. 293 dated 02.02.2021.
          </p>
          <a
            target="_blank"
            onClick={() => handleDownload(memo293)}
            class="btn btn-info"
          >
            For more details {">>"} Click Here
          </a>
        </div>
      </div>
      <div class="card mb-3">
        <div class="card-header bg-secondary text-white">
          Interview & Recruitment of Posts Gr. A & C (Retd.) on Contract Basis
        </div>
        <div class="card-body">
          <p>
            Interview and recruitment details for retired posts of Group A and C
            on a contract basis.
          </p>
          <a
            target="_blank"
            onClick={() => handleDownload(notmemo)}
            class="btn btn-info"
          >
            For more details {">>"} Click Here
          </a>
        </div>
      </div>
    </div>
  );
};
